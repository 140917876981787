import React from 'react';
import styled from 'styled-components';
import CategoryButton from './CategoryButton';
import ScrollContainer from 'react-indiana-drag-scroll';

const Menu = styled(ScrollContainer)`
    margin: 20px 0 20px 30px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
`

const CategoryMenu = () => {

    const items = [
        { key: 1, name: "Tudo", active: true},
        { key: 2, name: "Robôs" },
        { key: 3, name: "Drones" },
        { key: 4, name: "Carros" },
        { key: 5, name: "Robôs" },
        { key: 6, name: "Drones" },
        { key: 7, name: "Carros" }  
    ]


    return (
        <Menu>
            {items.map(item => 
                <CategoryButton {...item} />
            )}
        </Menu>
    )
}

export default CategoryMenu;