import React from 'react';
import styled from 'styled-components';

const Item = styled.div`
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    background-color: #CCC;
    flex: 0 0 20vw;
    height: 20vh;
    justify-content: flex-end;
    border-radius: 15px;
    background-image: url("${props => props.image}");
    background-size: cover;
    background-position: center;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
`;

const Title = styled.div`
    font-size: 22px;
    color: #FFF;
    margin: 15px 0 0 0;
    padding: 0 15px 15px 15px;
    user-select: none;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
`;

const CategorySectionItem = ({ title, image }) => {

    return (
        <Item image={image}>
            <Title>{title}</Title>
        </Item>
    )
}

export default CategorySectionItem;