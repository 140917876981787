import React from 'react';
import styled from 'styled-components';
import CategorySectionItem from './CategorySectionItem';
import ScrollContainer from 'react-indiana-drag-scroll';

const Wrapper = styled.div`
    margin: 30px 0 50px 30px;
`

const Title = styled.div`
    font-weight: bold;
    font-size: 22px;
    color: #FFF;
    margin-bottom: 25px;
`;

const Items = styled(ScrollContainer)`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
`;

const CategorySection = ({ title }) => {

    const items = [
        { key: 1, title: "Aventura na Lua", image: "https://i.pinimg.com/474x/e5/06/21/e50621c40fcb3a45a9fb1c3abec0f328.jpg"},
        { key:2, title: "Aventura na Lua", image: "https://i.pinimg.com/474x/e5/06/21/e50621c40fcb3a45a9fb1c3abec0f328.jpg"},
        { key: 3, title: "Aventura na Lua", image: "https://i.pinimg.com/474x/e5/06/21/e50621c40fcb3a45a9fb1c3abec0f328.jpg"},
        { key: 4, title: "Aventura na Lua", image: "https://i.pinimg.com/474x/e5/06/21/e50621c40fcb3a45a9fb1c3abec0f328.jpg"},
        { key: 5, title: "Aventura na Lua", image: "https://i.pinimg.com/474x/e5/06/21/e50621c40fcb3a45a9fb1c3abec0f328.jpg"},
        { key: 6, title: "Aventura na Lua", image: "https://i.pinimg.com/474x/e5/06/21/e50621c40fcb3a45a9fb1c3abec0f328.jpg"}
    ]

    return (
        <Wrapper>
            <Title>{title}</Title>
            <Items>
                {items.map(item => 
                    <CategorySectionItem {...item}/>
                )}
            </Items>
        </Wrapper>
    )
}

export default CategorySection;