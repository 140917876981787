import React from 'react';
import CategoryMenu from '../components/CategoryMenu';
import { createGlobalStyle } from 'styled-components';
import CategorySection from '../components/CategorySection';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

  html {
    background-color: #22343C;
  }

  div {
    font-family: 'Source Sans Pro', sans-serif;
  }

`;

const Catalog = () => {

    return (
        <div>
            <CategoryMenu />
            <CategorySection title="Programe novamente" />
            <CategorySection title="Explore outros mundos" />
            <GlobalStyle />
        </div>
    )

}

export default Catalog;