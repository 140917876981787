import React from 'react';
import styled from 'styled-components';

const buttonActiveStyle = `background-color: #FF565E;`;
const iconCircleActiveStyle = `
    border: 1px solid #FFF;
    background-color: #FFF;
`;
const titleActiveStyle = `color: #FFF;`;

const Button = styled.div`
    height: 12vh;
    background-color: #30444E;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    ${props => props.active && buttonActiveStyle}

    &:hover {
        ${buttonActiveStyle}
    }
`

const IconCircle = styled.div`
    border: 1px solid #475E69;
    border-radius: 100%;
    width: 7vh;
    height: 7vh;

    ${props => props.active && iconCircleActiveStyle}

    ${Button}:hover & {
        ${iconCircleActiveStyle}
    }
`;

const Title = styled.div`
    color: #96A7AF;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 800;

    ${props => props.active && titleActiveStyle}

    ${Button}:hover & {
        ${titleActiveStyle}
    }
`;

const CategoryButton = (props) => {
    return (
        <Button {...props}>
            <IconCircle {...props}/>
            <Title {...props}>
                {props.name}
            </Title>
        </Button>
    )
}

export default CategoryButton;